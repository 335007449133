<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div>
          <!-- 统计 -->
          <div class="data-statistics">
            <div class="item">
              <div class="br">
                <div class="title">账户余额</div>
                <div class="money">
                  <span class="red">{{ balanceInfo.balance }}</span>元
                </div>
              </div>
              <br />
              <top-up />
              <!-- <span class="btm" @click="recharge">马上充值</span> -->
            </div>

            <!-- Dialog xiaomao2-->
              <div class="recharge-hint">
                <div><b>充值须知</b></div>
                <p>1、非商家请勿充值，本站产品为：云仓礼品一键代发！ </p>
                <p>2、严禁用于刷单、恶意退款等诈骗违法犯罪行为，一经发现：上报、拉黑、封号！</p>
		<p>3、充值后，没下过单可以马上安排退款事宜，把支付的底单切图（看完整编号）</p>
		<p>4、如已下单，需要已发的快递正常签收后15天无任何异常/或恶意投诉反馈，方可以申请退款。</p>
                <p>5、如果充值异常，请联系客服微信。</p>

		<!-- 分站充值提示 开始 -->
                <p v-if="pay_memo!=''" v-html="pay_memo" style="margin-top:10px;"></p>
		<!-- 分站充值提示 结束 -->
              </div>

		<!-- 客服 二维码 -->
		<div class="top_case-three" style="float:right;">
		  <div class="box-card">
		    <div class="clearfix" style="padding-bottom:5px;">
		      <span class="kf-title">专属客服</span>
		      <span class="kf-cc">扫码加微信</span>
		    </div>
		    <div class="top_case-code">
		      <img class="img" :src="$store.getters.getSiteInfo.wechat_service" />
		    </div>

		  </div>
		</div>

          </div>
       <!-- 统计 结束 -->

	  <!-- 表格 -->
          <div class="table">
            <div class="table-menu">

              <!--  充值开始  -->

              <div class="content">
                <div class="name">
                    充值账户：<span>{{ accountzi }}</span>
                </div>
                <div class="price">
                    <div class="packing">
                        <div @click="selectAmount(index, item)" class="packing-item" :class="{ active: selectAmountIndex === index }" v-for="(item, index) in rechargeAmount" :key="index">
                            {{ item }}元
                            <img class="dagou" src="~assets/icons/dagou.png" />
                        </div>
                    </div>
                </div>
                <div>
                  <div class="payway-btn">
                    <span>支付方式：</span>
                    <div class="way">
                      <div class="payway" @click="selectAmount1(index, item.value)" :class="{ active: selectzhi === index }" v-for="(item, index) in rechargezhi" :key="index">
                        <img class="yue" src="~assets/icons/yue.png" /> <img class="dagou" src="~assets/icons/dagou.png" />
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                    <el-link type="success">推荐使用支付宝支付</el-link>
                  </div>
                  <div>
                    <el-row :gutter="20" v-if="isDisplay" v-show="isCode"><el-col>
                      <div v-show="selectzhi === 0 ? isDisplay : false" class="grid-content bg-purple">
                        <!-- <img class="codeimgs" :src="qianHtml" /> <br />
                        <img class="codeimgs" :src="faceHtml" /><br />
                        <img class="codeimgs" :src="jlmHtml" /><br />-->
                        <span v-show="!show" class="count qrcode">支付剩余时间{{ count }} s</span><br />
                        <!-- <span v-show="show" @click="getCode">获取验证码</span> -->
                        <span style="position: absolute; margin: 35px 0">
                          <el-link type="primary">请使用支付宝支付</el-link>&nbsp;
                          <el-link type="warning">请严格按照扫码后的金额付款 否则可能造成充值不到账等异常1</el-link>
                        </span><br />
                      </div>
                    
                      <div v-show="selectzhi === 1 ? isDisplay : false" class="grid-content bg-purple">
                        <img class="codeimgs" :src="jlmCode" /><!-- <br /> -->
                        <span v-show="!show" class="count qrcode">支付剩余时间{{ count }} s</span><br />
                        <!-- <span v-show="show" @click="getCode">获取验证码</span> -->
                        <span style="position: absolute; margin: 35px 0"> 
                          <el-link type="success"><p style="font-size:18px;">请使用微信支付</p></el-link>&nbsp;
                          <el-link type="warning">请严格按照扫码后的金额付款 否则可能造成充值不到账等异常2</el-link>
                        </span><br />
                      </div>
                    </el-col> </el-row>
                  <br /><br />
                  </div>
                </div>
                <div class="input-num">
                  <!--<p v-if="faceShow == 'fmpay'">
                    <span @click="confirmRecharge" class="btn fmpay"> 确认充值 </span>
                  </p>
                  <p v-if="faceShow == 'facepay'">
                    <span @click="faceToFaceDisplay" class="btn2 facepay">支付宝扫码支付</span>
                  </p>-->
                  <p> <!--  v-if="faceShow == 'vpay'" v-show="isCode && selectzhi == 0" -->
                    <span @click="changeDisplay" class="btn2 vpay" v-show="isCode">扫码充值</span>
                  </p>
                  <!--<p v-if="faceShow == 'jlmpay'">
                    <span @click="jlmPlay" class="btn2 jlmpay">扫码充值2</span>
                    <span @click="setTestPost" class="btn2 jlmpay">扫码充值2</span>
                  </p>-->
                </div>
              </div>
              <!--  充值结束  -->

            </div>
            
          </div>

          <!-- 二维码付款 Dialog -->
          <div class="qrcodeDialog">
            <el-dialog title="扫二维码付款" width="400px" :visible.sync="qrcodeDialogVisible" @close="closeQrcodeDialog">
	    
                <div class="messages" v-if="pay_messages!=''" v-html="pay_messages">
                </div>
              <div class="qrcodediv">
                <img class="codeimgs" :src="payQrcode" />
                <div class="qrcodemsg">
                  <span class="qrcodeprice">支付金额{{qrcodePrice}}元</span>
                  <span class="qrcodepay vpay" v-show="isCode && selectzhi == 0">用支付宝扫码充值</span>
                  <span class="qrcodepay vpay" v-show="isCode && selectzhi == 1">用微信扫码充值</span>
                </div>
                <span v-show="!show" class="count qrcode">支付剩余时间{{ count }} s</span>
              </div>
            </el-dialog>
          </div>

        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
// testarea
import AppMain from 'components/baseStructure/AppMain'
import { testPost, topuprice, moneyInfo, getSubTrad, getUserMoney, getQrcodePrice, vPayQr, qrCheck, faceMsg, faceAlipay, jlmPay, jlmMsg } from 'network/api'

import { Message } from 'element-ui'


// import TopUp from '../../../components/TopUp.vue'
export default {
  components: {
    AppMain //,
    //TopUp
  },
  inject: ['mainReload', 'reload'],
  data () {
    return {
      isShow: false,
      isCode: true, // 显示支付宝扫码支付false
      show: true,
      count: '',
      qianHtml: '',
      faceHtml: '',
      jlmHtml: '',
      jlmCode: '',
      isDisplay: false,
      redialogVisible: false,
      activeIndex: '0',
//      dialogVisible: false, // 余额充值  testarea
      qrcodeDialogVisible: false,
      rechargeAmount: ['20', '50', '100'],
      rechargezhi: [
        {
          name: '支付宝支付',
          value: 'alipay'
        },
        {
          name: '微信支付',
          value: 'wechat'
        }
      ],
      faceShow: '',
      payShow: false,
      payType: '2',
      amountType: 'alipay',
      selectAmountIndex: '',
      selectzhi: 0,
      num: 50, // undefined
    //  tixianNum: 1,
      controls: false,
    //  tixianControls: false,
      balanceInfo: {},
      moneyObj: {},
      alipay: '',
      accountzi: '',
      trueName: '',
      currentPage: 1,
      moneylogId: '',
      reasonVal: '',
      id: '',
      input: '',
      imgUrl: '',
      price: '',
      timer: null,
      timNum: 0,
      awardName: '',
      awardUp: '',
      groupData: '',
      live: '',
      pay_messages: '',	// 付款提示
      pay_memo: '',	// 充值提示
      payQrcode: '',
      qrcodePrice: ''
    }
  },
  computed: {
  },
  created () {
    if (this.selectAmountIndex === 1) {
      this.num = 300
    }
    this.setQrcodePrice()
    // 获取余额信息  testarea
    moneyInfo().then((res) => {
      this.balanceInfo = res.data
    })
    // 获取支付宝账号
    getUserMoney().then(res => {
      if (res.code !== 1) {
        return this.$message.warning(res.msg)
      }
      this.accountzi = res.data.username
      this.alipay = res.data.ali_pay
      this.trueName = res.data.true_name
      this.avatar = res.data.avatar
    })
    const face = JSON.parse(localStorage.getItem('siteInfo'))
    this.faceShow = face.pay_type
  },
  methods: {
  // 支付剩余时间
    getCode() {
      const TIME_COUNT = 180
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    changeDisplay() {
      this.count = ''
      this.isDisplay = !this.isDisplay
      setTimeout(() => {
        this.qrCode()
        // this.getCode()
      }, 500)
      // 扫码支付
    },
    faceToFaceDisplay() {
      this.count = ''
      this.isDisplay = !this.isDisplay
      setTimeout(() => {
        this.faceToFace()
      }, 500)
      // 扫码支付
    },
    setTestPost(){
	testPost().then(res => {
        if (res.code === 1) {
          this.$message({ message: '测试成功', type: 'success' })
        } else {
          return this.$message.error({ message: res.msg })
        }
      })
    },
    qrCode() {
      const price = this.num
      const type = this.payType
      if (price === undefined || '') {
        return false
      }
      vPayQr(price,type).then(res => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.getCode()
          this.payQrcode = res.data.payQRcode
	  this.qrcodePrice = res.data.reallyPrice
          this.pay_messages = res.data.pay_messages

          this.showQrcode()
          const orderId = res.data.orderId
          setTimeout(() => {
            this.timer = setInterval(() => {
              this.timNum = this.timNum + 1
              // eslint-disable-next-line eqeqeq, no-self-compare
              if (this.count <= 1) {
                // this.dialogVisible = false
                clearInterval(this.timer)
                this.$notify.warning({ title: '充值超时', duration: 5000 })
                this.qrcodeDialogVisible = false
                // clearInterval(this.timer)
                this.setQrcodePrice ()
                return false
              }
              qrCheck(orderId).then(res => {
                if (res.code !== 1) {
                  setTimeout(() => {
                    // this.$notify.warning({ title: res.msg, duration: 2000 })
                  }, 1000)
                }
		if(this.qrcodeDialogVisible == false){
		  clearInterval(this.timer)
		  this.timer = null
		}
                if (res.code === 1) {
                  clearInterval(this.timer)
                  this.timer = null
                  setTimeout(() => {
                    this.qrcodeDialogVisible = false
                    //this.dialogVisible = false
                  }, 2000)
                  return Message.success(res.msg)
                }
              })
            }, 3000)
          }, 5000)
        }
      })
    },
    // 支付宝面对面付
    faceToFace() {
      const amount = this.num
      if (amount === undefined || '') {
        return false
      }
      faceAlipay(amount).then(res => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.getCode()
          this.faceHtml = res.data.qrcode
          const orderId = res.data.orderid
          setTimeout(() => {
            this.timer = setInterval(() => {
              this.timNum = this.timNum + 1
              // eslint-disable-next-line eqeqeq, no-self-compare
              if (this.count <= 1) {
                // this.dialogVisible = false
                clearInterval(this.timer)
                this.$notify.warning({ title: '充值超时', duration: 5000 })
                return false
              }
              faceMsg(orderId).then(res => {
                if (res.code !== 1) {
                  setTimeout(() => {
                    // this.$notify.warning({ title: res.msg, duration: 2000 })
                  }, 1000)
                }
                if (res.code === 1) {
                  clearInterval(this.timer)
                  // this.timer = null
                  setTimeout(() => {
                    // this.dialogVisible = false
                  }, 2000)
                  return Message.success(res.msg)
                }
              })
            }, 3000)
          }, 5000)
        }
      })
    },

    // jlm
    jlmPlay() {
      this.count = ''
      this.isDisplay = !this.isDisplay
      setTimeout(() => {
        this.jlm()
      }, 500)
      // 扫码支付
    },
    jlm() {
      const amount = this.num
      if (amount === undefined || '') {
        return false
      }
      jlmPay(amount, this.amountType).then(res => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.getCode()
          // eslint-disable-next-line eqeqeq
          if (this.amountType == 'alipay') {
            this.jlmHtml = res.data.qr
          // eslint-disable-next-line eqeqeq
          } else if (this.amountType == 'wechat') {
            this.jlmCode = res.data.qr
          }
          const id = res.data.id
          setTimeout(() => {
            this.timer = setInterval(() => {
              // eslint-disable-next-line eqeqeq
              if (this.count <= 1) {
                clearInterval(this.timer)
                this.$notify.warning({ title: '充值超时', duration: 5000 })
                // this.dialogVisible = false
                // return false
              }
              jlmMsg(id).then(res => {
                console.log(res)
                if (res.code !== 1) {
                  setTimeout(() => {
                    // this.$notify.warning({ title: res.msg, duration: 2000 })
                  }, 1000)
                }
                if (res.code === 1) {
                  clearInterval(this.timer)
                  // this.timer = null
                  setTimeout(() => {
                    // this.dialogVisible = false
                  }, 2000)
                  return Message.success(res.msg)
                }
              })
            }, 1000)
          }, 1000)
        }
      })
    },

    // 提交充值按钮
    submit() {
      getSubTrad({ trade: this.input }).then(res => {
        if (res.code === 1) {
          this.$message({ message: '充值成功', type: 'success' })
        } else {
          return this.$message.error({ message: res.msg })
        }
      })
    },
    // 刷新按钮
    renovate() {
      moneyInfo().then(res => {
        if (res.code === 1) {
          this.balanceInfo = res.data
          this.$message({ message: '刷新成功', type: 'success' })
        } else {
          this.$message.error({ message: '刷新失败' })
        }
        // console.log(res)
      })
    },
    // 马上充值
    showRecharge() {
      // this.dialogVisible = true
    },
    recharge() {
      this.redialogVisible = true
      this.imgUrl = this.$store.getters.getSiteInfo.alipay_paymentcode
    },
    // 选择充值金额点击
    selectAmount(index, item) {
      this.selectAmountIndex = index
      this.num = item
    },
    // 充值方式
    selectAmount1(index, value) {
      this.selectzhi = index
      this.amountType = value
      if(value === 'wechat')    this.payType = 1
      else if(value === 'alipay')    this.payType = 2
      // 1 'wechat'
      this.setQrcodePrice()
    },
    // 充值二维码金额列表
    setQrcodePrice () {
      getQrcodePrice({
        type: this.payType
      }).then((res) => {
        if(res.code == 1){
          this.rechargeAmount = res.data.price
	  this.pay_memo = res.data.pay_memo
	} else {
	  this.num = 0
	  this.rechargeAmount = []
	  this.$message.error({ message: res.msg })
	}
      })
    },
    // 关闭Dialog回调
    closePayDialog() {
      clearInterval(this.timer)
      this.count = 0
      // this.timer = null
      // console.log('关闭')
      setTimeout(() => {
        this.refresh()
      }, 300)
    },
    refresh() {
      this.reload()
    },
    // 计数器值改变
    handleChange(val) {},
    // 确认充值
    confirmRecharge() {
      if (this.num) {
        topuprice(this.num.toString(), this.amountType).then(res => {
          if (res.code !== 200) {
            return this.$message(res.msg)
          }
          if (res.code === 200) {
            window.open(res.data.payUrl, '_blank')
            this.$nextTick(() => {
              //  this.dialogVisible = false
            })
          }
        })
      } else {
        this.$message({
          message: '请输入金额',
          type: 'warning'
        })
      }
    },
    // 关闭提现Dialog回调
    closeQrcodeDialog() {
      this.qrcodeDialogVisible = false
      clearInterval(this.timer)
      this.timer = null
      this.setQrcodePrice ()

      setTimeout(() => {
        this.refresh()
      }, 300)
    },
    // 提现点击
    showQrcode() {
      this.qrcodeDialogVisible = true
    },
    // 查询
    clickQuery() {
      this.moneylogId = this.id
      this.getMoneylist()
    },
    // 重置
    clickReset() {
      this.mainReload()
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>

<style scoped src="styles/views/account/recharge.css"></style>

